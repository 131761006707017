import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import MuiChip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { DeviceEmulator, DeviceFrameset } from "react-device-frameset";
import "react-device-frameset/styles/marvel-devices.min.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

// Íconos de Material-UI
import MedicalInformationIcon from "@mui/icons-material/MedicalInformation";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

// Importa tus imágenes (ajusta las rutas y nombres según tu proyecto)
import OrientationImage from "./features/OrientationImage.png";
import ReminderImage from "./features/ReminderImage.png";
import CallcenterImage from "./features/CallCenterImage.png";

const DEFAULT_DEVICE = "iPhone X";

const items = [
  {
    icon: <MedicalInformationIcon />,
    title: "Orientación médica personalizada",
    mobileTitle: "Orientación",
    description:
      "Orienta a tus pacientes al especialista perfecto para su caso.",
    media: {
      type: "image",
      src: OrientationImage,
      alt: "Orientación médica personalizada",
    },
  },
  {
    icon: <NotificationsActiveIcon />,
    title: "Notificaciones de seguimiento",
    mobileTitle: "Notificación",
    description:
      "Agenda controles periódicos y envía recordatorios personalizados.",
    media: {
      type: "image",
      src: ReminderImage,
      alt: "Notificaciones de seguimiento",
    },
  },
  {
    icon: <WhatsAppIcon />,
    title: "Call Center por WhatsApp",
    mobileTitle: "Call Center",
    description:
      "Disfruta de un servicio de Call Center 24/7 por la red más utilizada de LATAM.",
    media: {
      type: "image",
      src: CallcenterImage,
      alt: "Call Center por WhatsApp",
    },
  },
];

const Chip = styled(MuiChip)(({ theme }) => ({
  variants: [
    {
      props: ({ selected }) => selected,
      style: {
        background:
          "linear-gradient(to bottom right, hsl(210, 98%, 48%), hsl(210, 98%, 35%))",
        color: "hsl(0, 0%, 100%)",
        borderColor: theme.palette.primary.light,
        "& .MuiChip-label": {
          color: "hsl(0, 0%, 100%)",
        },
        ...theme.applyStyles("dark", {
          borderColor: theme.palette.primary.dark,
        }),
      },
    },
  ],
}));

function MobileLayout({
  selectedItemIndex,
  handleItemClick,
  selectedFeature,
  renderMedia,
}) {
  if (!items[selectedItemIndex]) {
    return null;
  }

  return (
    <Box
      sx={{
        display: { xs: "flex", sm: "none" },
        flexDirection: "column",
        gap: 0,
      }}
    >
      {/* Lista de Chips para vista móvil */}
      <Box
        sx={{
          display: "flex",
          gap: 0,
          overflow: "auto",
          padding: "1em",
          justifyContent: "space-around",
        }}
      >
        {items.map(({ mobileTitle }, index) => (
          <Chip
            size="medium"
            key={index}
            label={mobileTitle}
            onClick={() => handleItemClick(index)}
            selected={selectedItemIndex === index}
            sx={
              selectedItemIndex === index
                ? {
                    backgroundColor: "#016075",
                    background: "#016075",
                    borderColor: "#016075",
                  }
                : {}
            }
          />
        ))}
      </Box>

      <div className="smaller-phone-wrapper">
        <div className="d-flex flex-column align-items-center smaller-phone-container">
          <DeviceFrameset device={DEFAULT_DEVICE}>
            {renderMedia()}
          </DeviceFrameset>
        </div>
      </div>
      <Typography
        variant="body2"
        sx={{
          color: "text.secondary",
          mb: 1.5,
          mt: 2,
          textAlign: { xs: "center", sm: "left" },
        }}
      >
        {selectedFeature.description}
      </Typography>
    </Box>
  );
}

MobileLayout.propTypes = {
  handleItemClick: PropTypes.func.isRequired,
  selectedFeature: PropTypes.shape({
    description: PropTypes.string.isRequired,
    icon: PropTypes.element,
    media: PropTypes.shape({
      type: PropTypes.oneOf(["video", "image"]).isRequired,
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    }).isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  selectedItemIndex: PropTypes.number.isRequired,
  renderMedia: PropTypes.func.isRequired,
};

export default function Features() {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
  };

  const selectedFeature = items[selectedItemIndex];

  // Function to render media with or without DeviceFrameset
  const renderMedia = () => {
    const { media } = selectedFeature;
    const mediaContent =
      media.type === "video" ? (
        <video
          src={media.src}
          alt={media.alt}
          autoPlay
          loop
          muted
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      ) : (
        <img
          src={media.src}
          alt={media.alt}
          style={{
            borderRadius: "16px",
            width: "auto !important",
            height: "auto",
            objectFit: "contain",
          }}
        />
      );

    return isMobile ? (
      mediaContent
    ) : (
      <DeviceFrameset device={DEFAULT_DEVICE}>{mediaContent}</DeviceFrameset>
    );
  };

  return (
    <Container id="features" sx={{ py: { xs: 8, sm: 16 } }}>
      <Box sx={{ width: { sm: "100%", md: "100%" } }}>
        <Typography
          component="h2"
          variant="h4"
          align="center"
          gutterBottom
          sx={{ color: "text.primary" }}
        >
          Características de <b className="animated-gradient-text">Vita:</b>
        </Typography>
        <Typography
          variant="body1"
          align="center"
          sx={{ color: "text.secondary", mb: { xs: 0, sm: 4 } }}
        >
          Vita optimiza tu acceso a la atención médica con tecnología avanzada.
          Conoce nuestras funciones clave:
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row-reverse" },
          gap: 2,
          maxWidth: { sm: "100%", md: "90%", lg: "80%" },
          mx: "auto",
        }}
      >
        {/* Opciones y contenido seleccionado (versión desktop) */}
        <div>
          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              flexDirection: "column",
              gap: 2,
              height: "100%",
            }}
          >
            {items.map(({ icon, title, description }, index) => (
              <Box
                key={index}
                component={Button}
                onClick={() => handleItemClick(index)}
                sx={[
                  (theme) => ({
                    p: 2,
                    height: "100%",
                    width: "100%",
                    "&:hover": {
                      backgroundColor: theme.palette.action.hover,
                    },
                  }),
                  selectedItemIndex === index && {
                    backgroundColor: "action.selected",
                  },
                ]}
              >
                <Box
                  sx={[
                    {
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: { xs: "center", sm: "flex-start" },
                      gap: 1,
                      textAlign: { xs: "center", sm: "left" },
                      textTransform: "none",
                      color: "text.secondary",
                    },
                    selectedItemIndex === index && {
                      color: "text.primary",
                    },
                  ]}
                >
                  {icon}
                  <Typography variant="h6">{title}</Typography>
                  <Typography variant="body2">{description}</Typography>
                </Box>
              </Box>
            ))}
          </Box>

          {/* Versión móvil */}
          <MobileLayout
            selectedItemIndex={selectedItemIndex}
            handleItemClick={handleItemClick}
            selectedFeature={selectedFeature}
            renderMedia={renderMedia}
          />
        </div>

        {/* Muestra la imagen seleccionada (versión desktop) */}
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            width: { xs: "100%", md: "70%" },
          }}
        >
          <Card
            variant="outlined"
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              pointerEvents: "none",
            }}
          >
            <Box
              sx={{
                m: "auto",
                width: "100%",
                maxWidth: 600,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                p: 2,
              }}
            >
              {renderMedia()}
            </Box>
          </Card>
        </Box>
      </Box>
    </Container>
  );
}
