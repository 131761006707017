import React, { useEffect, useRef } from "react";
import { Typography } from "@mui/material";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useInView } from "react-intersection-observer";
import "./CinematicSection.css";
import isafari from "./isafari.mp4";
import ichrome from "./ichrome.webm";
import { useTheme } from "@mui/material/styles";

gsap.registerPlugin(ScrollTrigger);

export default function CinematicSection() {
  let theme = useTheme();
  const overlayRef = useRef(null);
  const titleRef = useRef(null);
  const subtitleRef = useRef(null);
  const videoRef = useRef(null);

  const { ref: sectionRef, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
    onChange: (inView) => {
      console.log("CinematicSection inView:", inView);
    },
  });

  useEffect(() => {
    const overlay = overlayRef.current;
    const title = titleRef.current;
    const subtitle = subtitleRef.current;
    const video = videoRef.current;

    gsap.set(title, { opacity: 0, y: 50 });
    gsap.set(subtitle, { opacity: 0, y: 50 });
    if (inView) {
      const tl = gsap.timeline();

      tl.to(overlay, {
        opacity: 1,
        duration: 1.2,
        ease: "power2.inOut",
      }).to([title, subtitle], {
        opacity: 1,
        y: 0,
        delay: 1,
        duration: 0.3,
        ease: "power2.out",
        stagger: 0.1,
      });
    }
  }, [inView]);

  return (
    <div ref={sectionRef} className="cinematic-section">
      <div className="content-overlay" ref={overlayRef}>
        <div className="content-wrapper">
          <div className="title-wrapper">
            <Typography ref={titleRef} variant="h2" className="animated-word">
              Transformamos la experiencia del paciente
            </Typography>
          </div>

          <div className="video-container">
            <video
              className="fullscreen-video iphone"
              autoPlay
              muted
              playsInline
              ref={videoRef}
            >
              <source src={isafari} type="video/mp4" />
              <source src={ichrome} type="video/webm" />
              Your browser does not support the video tag.
            </video>
          </div>

          <Typography
            ref={subtitleRef}
            className="animated-subtitle"
            sx={{
              color: "text.secondary",
            }}
          >
            Un par de mensajes para llegar al médico correcto. El único call
            center que funciona 24/7.
          </Typography>
        </div>
      </div>
    </div>
  );
}
