import React, { useEffect, useRef, useState } from "react";
import { Box, Container, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { MedicalServices, Person, Business } from "@mui/icons-material";
import { useInView } from "react-intersection-observer";
import gsap from "gsap";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cards";
import { EffectCards, Pagination, Navigation } from "swiper/modules";

import "./Benefits.css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const StyledCard = styled(Box)(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius * 3,
  backgroundPosition: "center center",
  backgroundSize: "cover",
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 10px 30px rgba(0, 0, 0, 0.5)"
      : "0 10px 30px rgba(0, 0, 0, 0.5)",
  ":hover": {
    boxShadow:
      theme.palette.mode === "dark"
        ? "0 10px 30px rgba(255, 255, 255, 0.1)"
        : "0 10px 30px rgba(0, 0, 0, 0.2)",
  },
}));

const TextContent = styled(Box)({});

const stakeholders = [
  {
    icon: MedicalServices,
    header: "Doctores",
    headerNonHighlighted: "Para",
    headerHighlighted: "Doctores:",
    title: "Optimiza tu práctica médica",
    description:
      "Accede a información detallada de tus pacientes antes de cada consulta.",
    description2:
      " Concéntrate en brindar la mejor atención sin preocuparte por otras tareas.",
    imgUrl: "/benefits/doctor.png",
  },
  {
    icon: Person,
    header: "Pacientes",
    headerNonHighlighted: "Para",
    headerHighlighted: "Pacientes:",
    title: "Encuentra al especialista perfecto",
    description: "Conecta instantáneamente con el mejor profesional.",
    description2: "Agenda tus citas de una manera nueva y sencilla.",
    imgUrl: "/benefits/patient.png",
  },
  {
    icon: Business,
    header: "Instituciones",
    headerNonHighlighted: "Para",
    headerHighlighted: "Instituciones:",
    title: "Potencia la eficiencia operativa",
    description: "Disminuye costos y aumenta la asistencia a citas. ",
    description2: "Mejora la satisfacción de tus pacientes.",
    imgUrl: "/benefits/institution.png",
  },
];

const VitaBenefits = () => {
  const theme = useTheme();
  const cardsRef = useRef([]);
  cardsRef.current = [];

  const addToRefs = (el) => {
    if (el && !cardsRef.current.includes(el)) {
      cardsRef.current.push(el);
    }
  };

  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = useRef(null);

  useEffect(() => {
    const tl = gsap.timeline();
    if (inView) {
      tl.fromTo(
        ".benefits-text",
        { opacity: 0, y: 250, scaleX: 1.4 },
        {
          opacity: 1,
          scaleX: 1,
          y: 0,
          duration: 0.7,
          ease: "power4.out",
        }
      ).fromTo(
        ".swiper-box",
        { opacity: 0, y: 250, scaleX: 1.4 },
        {
          opacity: 1,
          scaleX: 1,
          y: 0,
          duration: 0.7,
          ease: "power4.out",
        },
        "<0.2"
      );
    }
  }, [inView]);

  const handleWordClick = (index) => {
    console.log("index", index);
    swiperRef.current.slideTo(index);
  };

  return (
    <Box
      id="benefits"
      sx={{
        width: "100%",
        py: { xs: 4, md: 6 },
      }}
    >
      <Container maxWidth="lg">
        <div className="benefits-text">
          <Typography
            variant="h3"
            component="h2"
            align="center"
            gutterBottom
            className="benefits-title"
            sx={{ fontWeight: 500 }}
          >
            ¿Para quién es Vita?
          </Typography>

          <Box sx={{ textAlign: "center", mb: 4 }} className="benefits-buttons">
            <Box
              sx={{
                mt: 2,
                mb: 2,
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                width: "60%",
                mx: "auto",
                userSelect: "none",
                display: { xs: "none", md: "flex" },
              }}
            >
              {stakeholders.map((item, index) => (
                <Typography
                  key={index}
                  onClick={() => handleWordClick(index)}
                  sx={{
                    display: "inline-block",
                    cursor: "pointer",
                    lineHeightheight: "3rem",
                    width: "33%",
                    fontSize: activeIndex === index ? "1.5em" : "0.75rem",
                    fontWeight: activeIndex === index ? 800 : 300,
                    background:
                      activeIndex === index
                        ? "linear-gradient(90deg, #016075, #00c4cc)"
                        : "none",
                    WebkitBackgroundClip:
                      activeIndex === index ? "text" : "none",
                    WebkitTextFillColor:
                      activeIndex === index ? "transparent" : "inherit",
                    opacity: activeIndex === index ? 1 : 0.5,
                    transition: "all 0.25s cubic-bezier(0.4, 0, 0.2, 1)",
                  }}
                >
                  <p style={{ height: "100%", margin: 0, lineHeight: "3rem" }}>
                    {item.header}
                  </p>
                </Typography>
              ))}
            </Box>
          </Box>
        </div>

        <Box ref={ref} className="swiper-box">
          <Swiper
            onSwiper={(swiper) => (swiperRef.current = swiper)}
            onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)}
            modules={[EffectCards, Pagination, Navigation]}
            effect={"cards"}
            grabCursor={true}
            className="mySwiper"
            touchAngle={45}
            loop={true}
            pagination={{
              clickable: true,
            }}
            ref={swiperRef}
            navigation={{ enabled: true }}
          >
            {stakeholders.map((item, index) => (
              <SwiperSlide
                key={index}
                sx={{
                  width: "fit-content !important",
                  display: "flex",
                  justifyContent: "center",
                }}
                className="benefits-slide"
                ref={addToRefs}
              >
                <StyledCard
                  ref={addToRefs}
                  className="benefits-card"
                  sx={{
                    backgroundImage: `url(${item.imgUrl})`,
                    userSelect: "none",
                  }}
                >
                  <div className="card-overlay w-100 h-100">&nbsp;</div>
                  <div className="card-container h-100">
                    <div className="w-100 h-100 d-flex flex-column justify-content-between align-items-center">
                      <div className="mt-4">
                        <h1 className="card-header text-center">
                          {item.headerNonHighlighted}{" "}
                          <b className="text-center animated-gradient-text">
                            {item.headerHighlighted}
                          </b>
                        </h1>
                        <p className="card-title text-center">{item.title}</p>
                      </div>
                      <div className="mb-4">
                        <p className="card-description text-center">
                          {item.description}
                        </p>
                        <p className="card-description text-center">
                          {item.description2}
                        </p>
                      </div>
                    </div>
                  </div>
                </StyledCard>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Container>
    </Box>
  );
};

export default VitaBenefits;
