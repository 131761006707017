import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        sx={{
          color: "text.primary",
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        Preguntas frecuentes
      </Typography>
      <Box sx={{ width: "100%" }}>
        <Accordion
          className="faq-accordion"
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            className="faq-accordion"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <Typography component="h3" fontWeight="800" variant="subtitle2">
              ¿Qué hace Vita?
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="faq-accordion">
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: "100%", md: "70%" } }}
            >
              Vita es la manera más fácil de acceder a la atención médica en LATAM. Para los pacientes, es una manera rápida de encontrar al especialista médico adecuado. Para los centros médicos es la manera de llegar a más pacientes y agendar citas de forma eficiente.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="faq-accordion"
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2d-content"
            id="panel2d-header"
          >
            <Typography component="h2" fontWeight="800" variant="subtitle2">
              ¿Es segura mi información?
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="faq-accordion">
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: "100%", md: "70%" } }}
            >
              Sí. Según lo estipulado por la ley, cuidamos tu privacidad <b>siempre</b>. Tu información médica no se comparte con nadie además de tu médico. Lee nuestra <a href="/pp.html" target="_blank" rel="noopener noreferrer" style={{ color: "inherit", textDecoration: "underline" }}>política de privacidad</a> para más detalles.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
          className="faq-accordion"
        >
          <AccordionSummary
            className="faq-accordion"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3d-content"
            id="panel3d-header"
          >
            <Typography component="h3" fontWeight="800" variant="subtitle2">
              ¿Cómo cambio o cancelo mi cita?
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="faq-accordion">
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: "100%", md: "70%" } }}
            >
              Si necesitas cambiar o cancelar tu cita, usa el mismo canal donde
              la programaste (WhatsApp, web o llamada) o contacta directamente
              con el centro médico donde fue agendada.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
          className="faq-accordion"
        >
          <AccordionSummary
            className="faq-accordion"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4d-content"
            id="panel4d-header"
          >
            <Typography component="h3" fontWeight="800" variant="subtitle2">
              ¿Vita reemplaza al médico?
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="faq-accordion">
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: "100%", md: "70%" } }}
            >
              No, Vita solo te ayuda a encontrar al especialista correcto y
              agendar tu cita. Siempre es importante consultar directamente con
              un médico para recibir un diagnóstico y tratamiento.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel5"}
          onChange={handleChange("panel5")}
          className="faq-accordion"
        >
          <AccordionSummary
            className="faq-accordion"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5d-content"
            id="panel5d-header"
          >
            <Typography component="h3" fontWeight="800" variant="subtitle2">
              ¿Vita funciona con todos los centros médicos?
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="faq-accordion">
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: "100%", md: "70%" } }}
            >
              Sí, Vita se puede integrar con cualquier centro médico que quiera
              usar nuestra plataforma. Nos adaptamos a los sistemas que ya usan. 
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Container>
  );
}
