import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { useParams, Link } from "react-router-dom";
import { Container, Paper, Box } from "@mui/material";

const monospaceFonts = '"Roboto Mono", monospace !important';

export default function Documentation() {
  const [content, setContent] = useState("");
  const { "*": path } = useParams();

  useEffect(() => {
    const fetchDoc = async () => {
      try {
        const docPath = path
          ? path.endsWith(".md")
            ? path
            : `${path}.md`
          : "index.md";
        const response = await fetch(`/documentation/${docPath}`);
        if (!response.ok) {
          throw new Error("Document not found");
        }
        const text = await response.text();
        setContent(text);
      } catch (error) {
        setContent("# 404\nDocument not found");
      }
    };

    fetchDoc();
  }, [path]);

  // Custom components for ReactMarkdown
  const components = {
    h1: ({ children }) => (
      <Box
        component="h1"
        sx={{
          mt: 6,
          mb: 4,
          fontFamily: monospaceFonts,
        }}
      >
        {children}
      </Box>
    ),
    h2: ({ children }) => (
      <Box
        component="h2"
        sx={{
          mt: 5,
          mb: 3,
          fontFamily: monospaceFonts,
          fontWeight: "bold",
          borderRadius: "10px",
          backgroundColor: "var(--secondary-color-50)",
          width: "fit-content",
          p: 1,
        }}
      >
        {children}
      </Box>
    ),
    h3: ({ children }) => (
      <Box component="h3" sx={{ mt: 4, mb: 2 }}>
        {children}
      </Box>
    ),
    a: ({ href, children }) => {
      if (href?.startsWith("./")) {
        const cleanHref = href
          .replace(/\.md$/, "")
          .replace("./", "/documentation/");
        return (
          <Link
            to={cleanHref}
            style={{ color: "primary.main", textDecoration: "none" }}
          >
            {children}
          </Link>
        );
      }
      return (
        <a href={href} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      );
    },
    // Style code blocks
    pre: ({ children }) => {
      return (
        <Box
          sx={{
            backgroundColor: "grey.100",
            p: 2,
            borderRadius: 1,
            overflow: "auto",
            margin: "1em 0em !important",

            "& code": {
              fontFamily: monospaceFonts,
              fontSize: "0.875rem",
              width: "fit-content",
              lineHeight: 1.7,
              display: "block",
              whiteSpace: "pre",
              fontWeight: "bold",
            },
          }}
        >
          {children}
        </Box>
      );
    },

    p: ({ children }) => {
      return (
        <Box
          component="p"
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1,
            flexWrap: "wrap",
            "& code": {
              color: "var(--primary-color) !important",
              backgroundColor: "var(--secondary-color-50) !important",
              padding: "0.2em 0.4em !important",
              borderRadius: "16px !important",
              fontFamily: monospaceFonts,
              width: "fit-content !important",
            },
          }}
        >
          {children}
        </Box>
      );
    },

    code: ({ node, inline, className, children, ...props }) => {
      return (
        <code
          className={className}
          {...props}
          style={{
            fontFamily: monospaceFonts,
            fontSize: inline ? "0.9em" : "0.875rem",
            backgroundColor: inline ? "grey.100" : "transparent",
            padding: inline ? "0.2em 0.4em" : "0",
            borderRadius: inline ? "3px" : "0",
            whiteSpace: "pre",
            display: inline ? "inline" : "block",
            color: "var(--primary-color)",
          }}
        >
          {children}
        </code>
      );
    },
  };

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Paper
        elevation={0}
        sx={{
          p: 4,
          minHeight: "70vh",
          "& a": {
            color: "primary.main",
            textDecoration: "none",
            "&:hover": {
              textDecoration: "underline",
            },
          },
          "& pre": {
            margin: "1.5em 0",
          },
          "& p": {
            marginBottom: "1em",
          },
          "& ul, & ol": {
            marginBottom: "1.5em",
          },
          "& :not(pre) > code": {
            backgroundColor: "grey.100",
            p: "0.2em 0.4em",
            borderRadius: "3px",
            fontFamily: monospaceFonts,
            fontSize: "0.9em",
            whiteSpace: "pre",
          },
        }}
      >
        <ReactMarkdown remarkPlugins={[remarkGfm]} components={components}>
          {content}
        </ReactMarkdown>
      </Paper>
    </Container>
  );
}
