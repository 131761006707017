import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MarketingPage from "./template/MarketingPage";
import Documentation from "./template/components/Documentation";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/documentation/*" element={<Documentation />} />
        <Route path="/*" element={<MarketingPage />} />
      </Routes>
    </Router>
  );
}

export default App;
