import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Container,
  InputLabel,
  TextField,
  Typography,
  Stack,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import axios from "axios";
import { PROD_API_URL } from "../../const";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import BlobAnimation from "./BlobAnimation";

import "./Hero.css";
import { happyLog } from "./coolLog";

gsap.registerPlugin(ScrollTrigger);

export default function Hero() {
  const phrases = ["Simplificamos", "Optimizamos", "Aceleramos", "Mejoramos", "Facilitamos"];
  const wordRefs = useRef([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  setTimeout(() => {
    happyLog();
  }, 1000);

  useEffect(() => {
    wordRefs.current = wordRefs.current.slice(0, phrases.length);
  }, [phrases.length]);

  useEffect(() => {
    const rotateWords = () => {
      const currentWord = wordRefs.current[currentIndex];
      const nextIndex = (currentIndex + 1) % phrases.length;
      const nextWord = wordRefs.current[nextIndex];

      const tl = gsap.timeline({ defaults: { ease: "power3.inOut" } });

      tl.to(currentWord, {
        rotateX: 90,
        y: "-50%",
        opacity: 0,
        duration: 0.6,
        scale: 0.6,
        ease: "power2.in",
        onComplete: () => {
          gsap.set(currentWord, {
            rotateX: 0,
            y: "50%",
            opacity: 0,
            scale: 1,
          });
        },
      });

      tl.fromTo(
        nextWord,
        {
          rotateX: -90,
          y: "80px",
          opacity: 0,
          scale: 0.6,
        },
        {
          rotateX: 0,
          y: "-15%",
          scale: 1,
          opacity: 1,
          duration: 0.6,
          ease: "power2.out",
        },
        "-=0.3"
      );

      tl.add(() => {
        setCurrentIndex(nextIndex);
      });
    };

    const interval = setInterval(rotateWords, 3000);
    return () => clearInterval(interval);
  }, [currentIndex, phrases.length]);

  const theme = useTheme();

  // Refs para GSAP
  const heroRef = useRef(null);
  const titleRef = useRef(null);
  const subtitleRef = useRef(null);
  const formRef = useRef(null);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
  });

  const [errors, setErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  // GSAP ENTRY Animations
  useEffect(() => {
    const appbar = document.getElementById("appbar");
    const ctx = gsap.context(() => {
      const tl = gsap.timeline({ defaults: { ease: "power3.out" } });
      tl.delay(0.2);

      tl.from(titleRef.current, {
        y: 220,
        scaleX: 1.4,
        opacity: 0,
        duration: 0.6,
        ease: "power2.out",
      })
        .from(
          [subtitleRef.current, formRef.current],
          {
            y: 150,
            scaleX: 1.2,
            opacity: 0,
            duration: 0.8,
          },
          "-=0.3"
        )
        .from(appbar, {
          y: -100,
          opacity: 0,
          scaleY: 1.2,
          duration: 0.5,
          ease: "back.out(1.4)",
        });
    }, heroRef);

    return () => ctx.revert();
  }, []);

  // Validación de formulario
  useEffect(() => {
    const noErrors = Object.values(errors).every((error) => !error);
    const requiredFieldsFilled = ["email"].every((field) => formData[field] !== "");
    setIsFormValid(noErrors && requiredFieldsFilled);
  }, [errors, formData]);

  const regex = {
    email: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  };

  const validateField = (name, value) => {
    let error = "";

    switch (name) {
      case "email":
        if (!value) {
          error = "El correo electrónico es requerido.";
        } else if (!regex.email.test(value)) {
          error = "Ingrese un correo electrónico válido.";
        }
        break;
      default:
        break;
    }

    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    validateField(name, value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const submissionData = {
      name: formData.email,
      email: formData.email,
      message: "",
    };

    if (isFormValid) {
      setIsSubmitting(true);
      try {
        const response = await axios.post(`${PROD_API_URL}/contact`, submissionData);
        if (response.status === 200) {
          gsap.to(formRef.current, {
            scale: 1.05,
            duration: 0.2,
            yoyo: true,
            repeat: 1,
            ease: "power2.inOut",
          });

          setFormData({ name: "", email: "" });
          setSnackbar({
            open: true,
            message: "¡Mensaje enviado exitosamente!",
            severity: "success",
          });
        }
      } catch (error) {
        // Error shake animation
        gsap.to(formRef.current, {
          x: 10,
          duration: 0.1,
          yoyo: true,
          repeat: 3,
        });

        setSnackbar({
          open: true,
          message: "Error al enviar el mensaje. Por favor, intente nuevamente.",
          severity: "error",
        });
      } finally {
        setIsSubmitting(false);
        setIsFormValid(false);
      }
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  return (
    <Box
      ref={heroRef}
      sx={{
        width: "100%",
        position: "relative",
        minHeight: "80vh",
        background: "transparent",
      }}
    >
      {/* Animación de fondo */}
      <BlobAnimation />

      {/* Overlay gradiente (opcional, puedes quitarlo si no lo necesitas) */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
/*           background: `
            linear-gradient(
              to bottom,
              rgba(0, 0, 0, 0.2) 0%,
              rgba(0, 0, 0, 0.3) 50%,
              rgba(0, 0, 0, 0.9) 100%
            )
          `, */
          zIndex: 0,
        }}
      />

      {/* Contenido principal */}
      <Box
        id="hero"
        sx={{
          width: "100%",
          position: "relative",
          zIndex: 1,
          backgroundImage: "none",
        }}
      >
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            pt: { xs: 14, sm: 20 },
            pb: { xs: 8, sm: 12 },
          }}
        >
          <Stack
            spacing={2}
            sx={{ alignItems: "center", width: { xs: "100%", sm: "70%" } }}
          >
            {/* Título principal */}
            <Typography
              ref={titleRef}
              variant="h1"
              className="hero-title-primitive"
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: "center",
                fontSize: {
                  xs: "clamp(3rem, 10vw, 4rem)",
                  sm: "clamp(4rem, 14vw, 5.5rem)",
                  md: "clamp(4rem, 14vw, 5.5rem)",
                },
                lineHeight: {
                  xs: "1.1",
                  sm: "1.2",
                },
                textAlign: { xs: "center", sm: "left" },
                marginBottom: { xs: 3, sm: 3 },
                paddingX: { xs: 2 },
              }}
            >
              <div>
                <Box
                  sx={{
                    perspective: "1000px",
                    height: "1.5em",
                    width: "100%",
                    marginTop: "1em",
                    verticalAlign: "top",
                    display: "flex",
                    alignItems: "top",
                    justifyContent: "center",
                  }}
                >
                  {phrases.map((word, index) => (
                    <Typography
                      key={index}
                      ref={(el) => (wordRefs.current[index] = el)}
                      component="span"
                      variant="h1"
                      className="animated-gradient-text"
                      sx={{
                        fontSize: "inherit",
                        fontWeight: 700,
                        position: "absolute",
                        top: 0,
                        transformOrigin: "center",
                        backfaceVisibility: "hidden",
                        transform: "translateZ(0)",
                        overflow: "visible !important",
                        lineHeight: "1.5",
                        opacity: index === currentIndex ? 1 : 0,
                      }}
                    >
                      <b>{word}</b>
                    </Typography>
                  ))}
                </Box>
                <span className="hero-subtitle-fixed">el acceso a la salud.</span>
              </div>
            </Typography>

            {/* Subtítulo */}
            <Typography
              id="hero-subtitle"
              ref={subtitleRef}
              sx={{
                textAlign: "center",
                color: "text.secondary",
                width: { sm: "100%", md: "80%" },
                fontSize: { xs: "1.2rem", sm: "1.4rem" },
              }}
            >
              <div>
                Hacemos que <b>el acceso a los servicios de salud</b> sea simple y eficiente
                <b> en cuestión de segundos.</b>
              </div>
            </Typography>
          </Stack>
        </Container>
      </Box>

      {/* Snackbar de notificaciones */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}
