import React, { useEffect, useRef } from "react";
import { Typography } from "@mui/material";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useInView } from "react-intersection-observer";
import "./CinematicSection.css";
import macsafari from "./macsafari.mp4";
import macchrome from "./macchrome.webm";
import { useTheme } from "@mui/material/styles";

gsap.registerPlugin(ScrollTrigger);

export default function CinematicSectionAdmin() {
  let theme = useTheme();
  const overlayRef = useRef(null);
  const titleRef = useRef(null);
  const subtitleRef = useRef(null);

  const { ref: sectionRef, inView } = useInView({
    triggerOnce: true,
    threshold: 0.05,
    onChange: (inView) => {
      console.log("CinematicSectionAdmin inView:", inView);
    },
  });

  useEffect(() => {
    const overlay = overlayRef.current;
    const title = titleRef.current;
    const subtitle = subtitleRef.current;

    if (inView) {
      const tl = gsap.timeline();

      // Initial states
      gsap.set(overlay, { opacity: 0 });
      gsap.set(title, { opacity: 0, y: 50 });
      gsap.set(subtitle, { opacity: 0, y: 50 });

      tl.to(overlay, {
        opacity: 1,
        duration: 1.2,
        ease: "power2.inOut",
      }).to([title, subtitle], {
        opacity: 1,
        delay: 1,
        stagger: 0.5,
        y: 0,
        duration: 0.3,
        ease: "power2.out",
      });
    }
  }, [inView]);

  return (
    <div ref={sectionRef} className="cinematic-section admin">
      <div className="content-overlay" ref={overlayRef}>
        <div className="content-wrapper">
          <div className="title-wrapper">
            <Typography ref={titleRef} variant="h2" className="animated-word">
              Potenciamos la gestión médica
            </Typography>
          </div>

          <div className="video-container">
            <video className="fullscreen-video mac" autoPlay muted playsInline>
              <source src={macsafari} type="video/mp4" />
              <source src={macchrome} type="video/webm" />
              Your browser does not support the video tag.
            </video>
          </div>

          <Typography
            ref={subtitleRef}
            className="animated-subtitle"
            sx={{
              color: "text.secondary",
              maxWidth: "800px",
            }}
          >
            Liberamos tiempo para los doctores y <b>potenciamos</b> a los equipos
            administrativos.
          </Typography>
        </div>
      </div>
    </div>
  );
}
