import React, { useEffect, useRef } from "react";
import { Gradient } from "./gradient";
import "./BlobAnimation.css";

const BlobAnimation = () => {
  const canvasRef = useRef(null);
  const gradientRef = useRef(null);

  useEffect(() => {
    // Initialize gradient
    const gradient = new Gradient();
    gradientRef.current = gradient;

    // Custom config
    gradient.amp = 320;
    gradient.seed = 5;
    gradient.freqX = 14e-5;
    gradient.freqY = 29e-5;
    gradient.freqDelta = 1e-5;
    gradient.activeColors = [1, 1, 1, 1];

    // Initialize the gradient
    gradient.initGradient("#gradient-canvas");

    // Cleanup
    return () => {
      if (gradientRef.current) {
        gradientRef.current.disconnect();
      }
    };
  }, []);

  return (
    <div className="fade-overlay">
      <canvas
        ref={canvasRef}
        id="gradient-canvas"
        className="gradient-bg"
        data-js-darken-top
        data-transition-in
      />
    </div>
  );
};

export default BlobAnimation;
